import * as React from "react"
import Layout from "../components/layout"
import SEO from '../components/SEO'
import Hero from '../components/hero'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Banner from "../components/banner"

const ResourcesPage = () => {
  const { heroImage, textbookImages } = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "resources.jpg" }) {
				childImageSharp {
					fluid(quality: 100) {
						...GatsbyImageSharpFluid
					}
				}
			}
      textbookImages: allFile(filter: { relativeDirectory: { eq: "textbooks" } }) {
        edges {
          node {
            childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 95
                )
            }
            name
          }
        }
      }
    }
  `)

  const textbooks = [
    {
      title: "Campbell's Biology",
      img: "campbells",
      description: "Campbell's Biology is the gold-standard textbook for the USA Biolympiad. It is great for general biology and a must read for high school biology competitions.",
      link: "https://www.amazon.com/Campbell-Biology-11th-Lisa-Urry-dp-0134093410/dp/0134093410/",
      tag: "General Biology",
    },
    {
      title: "Vander's Human Physiology",
      img: "vanders",
      description: "Vander's Human Physiology is a great textbook to read if you want to learn more about human physiology.",
      link: "https://www.amazon.com/Vanders-Human-Physiology-15Th-NA/dp/1260085228/",
      tag: "Anatomy & Physiology",
    },
    {
      title: "Costanzo Physiology",
      img: "costanzo",
      description: "",
      link: "https://www.amazon.com/Costanzo-Physiology-Linda-S-PhD/dp/0323793339",
      tag: "Anatomy & Physiology",
    },
    {
      title: "Alberts' Molecular Biology of the Cell",
      img: "mboc",
      description: "Albert's Molecular Biology of the Cell is a cell biology textbook that explains cell biology concepts in incredible detail. It is very thorough but interesting.",
      link: "https://www.amazon.com/Molecular-Biology-Sixth-Bruce-Alberts/dp/0815345240",
      tag: "Cell Biology",
    },
    {
      title: "Lehninger's Principles of Biochemistry",
      img: "leh",
      description: "Lehninger's Principles of Biochemistry is an excellent textbook for students looking to understand the chemical processes behind biology. The diagrams are detailed, but it is fun to read from.",
      link: "https://www.amazon.com/Lehninger-Principles-Biochemistry-David-Nelson/dp/1429234148",
      tag: "Biochemistry",
    },
    {
      title: "Brooker's Genetics: Analysis & Principles",
      img: "brookers",
      description: "Brooker's Genetics: Analysis & Principles is an introductory genetics textbook that introduces students to new concepts while emphasizing experiments and practice.",
      link: "https://www.amazon.com/Genetics-Analysis-Principles-Robert-Brooker-ebook/dp/B01MUH4LV9",
      tag: "Genetics",
    },
    {
      title: "Raven's Biology of Plants",
      img: "ravens",
      description: "Raven's Biology of Plants is an in-depth textbook about plant biology. It contains extremely detailed diagrams, but it is fun to learn from.",
      link: "https://www.amazon.com/Raven-Biology-Plants-Loose-Leaf-Evert/dp/1464117802",
      tag: "Botany",
    },
  ]

  const otherResources = [
    {
      name: "USA Biolympiad Study Resources",
      link: "https://www.usabo-trc.org/src-content-information",
    },
    {
      name: "USA Biolympiad Past Exams",
      link: "https://www.usabo-trc.org/sites/default/files/allfiles/USABO%20Past%20Exams_0.pdf",
    },
    {
      name: "National Science Bowl Biology Questions",
      link: "https://science.osti.gov/wdts/nsb/Regional-Competitions/Resources/HS-Sample-Questions",
    },
    {
      name: "Past Biology-Related Science Olympiad Exams (ex. Anatomy & Physiology, Botany)",
      link: "https://scioly.org/tests/",
    },
  ]

  return (
    <Layout>
      <SEO title='Resources' description="Take a look at our list of resources that you can use to prepare for the International Biology Bowl." />
      <Hero image={heroImage} title="Resources" />
      <div className='max-w-screen-lg mx-auto py-10 px-4 sm:px-6 pt-20'>
        <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-4xl mb-2 sm:mb-3">
          Textbooks to Study
        </h1>
        <p className='mb-5 sm:mb-7 text-gray-500 text-md sm:text-lg'>
        Many of the questions in IBB competitions will be based on content from Campbell's Biology and the following textbooks.
        </p>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10'>
          {textbooks.map((book) => {
            return (
              <div className='overflow-hidden rounded-lg bg-gray-50 shadow-md'>
                {/* <a target="_blank" href={book.link}> */}
                <GatsbyImage
                  className="w-full h-auto object-cover"
                  image={getImage(
                    textbookImages.edges.find(
                      x => x.node.name === book.img
                    ).node.childImageSharp.gatsbyImageData
                  )}
                  alt={book.title}
                />
                {/* </a> */}
                <div className='p-6'>
                  <div className='flex'>
                    <p className='inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gradient-to-r from-blue-500 to-blue-700 text-white mb-3'>{book.tag}</p>
                  </div>
                  <h1 className="text-xl font-semibold">
                    {book.title}
                  </h1>
                  {/* <p className='text-left mt-2 text-base text-gray-500'>
                    {book.description}
                  </p> */}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='bg-gray-800 text-white'>
        <div className='max-w-screen-lg mx-auto py-10 px-4 sm:px-6'>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-10'>
            <div className='mb-4'>
              <h1 className="text-xl tracking-tight leading-10 sm:leading-none font-extrabold sm:text-2xl lg:text-3xl xl:text-4xl sm:mb-4">
                Past Exams
              </h1>
              <p className='mb-2 text-md text-gray-300'>Want to study for the next International Biology Bowl? View past exams and answer keys here.</p>
              <br />
              <a href="https://drive.google.com/drive/folders/1H5YcN29c07uFeQo1wQul5RSL5Wv-8ifW?usp=sharing" target="_blank" className="text-blue-700 text-center bg-blue-100 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 px-5 py-3 border border-transparent text-base font-medium rounded-md transition duration-150 ease-in-out">Past Exams</a>
            </div>
            <div>
              <h1 className="text-xl tracking-tight leading-10 sm:leading-none font-extrabold sm:text-2xl lg:text-3xl xl:text-4xl sm:mb-4">
                Other Resources
              </h1>
              <ul className='text-md text-gray-300 list-disc list-inside'>
                {otherResources.map(resource => {
                  return (
                    <a className='hover:text-blue-300' target="_blank" href={resource.link}><li>{resource.name}</li></a>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Banner />
    </Layout>
  )
}

export default ResourcesPage
